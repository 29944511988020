<template>
  <div id="content" class="content">
    <div class="dataOverview clearfix">
      <div class="top clearfix">
        <div class="line"></div>
        <div class="title fl">IBM</div>
        <!-- <a href="" class="fr"><button type="button" class="btn btn-primary">编辑信息</button></a> -->
      </div>
      <p class="infotext">
        IBM 是企业数字化转型的绝佳合作伙伴，凭借全球技术和人才网络，丰富的行IBM
        是企业数字化转型的绝佳合作伙伴，凭借全球技术和人才网络，丰富的行业经验和敏锐的行业洞察，IBM
        可以帮助企业利用认知、人工智能、云计算等先进技术进行数字化重塑，构建具有前瞻性业务模式，帮助企业始终站在业界的前沿。业经验和敏锐的行业洞察，IBM
        可以帮助企业利用认知、人工智能、云计算等先进技术进行数字化重塑，构建具有前瞻性业务模式，帮助企业始终站在业界的前沿。
      </p>
      <div class="chart-title">服务优势</div>
      <div class="data-box clearfix server">
        <div class="item fl">
          <div class="number">动态化拓展</div>
          <div class="text">组件化的结构设计和动态的插件框架保证了业务中台的可成长性</div>
        </div>
        <div class="item fl">
          <div class="number">灵活的业务配置编排</div>
          <div class="text">个性化配置及编排流程、营销活动等具体业务，满足业务随需而变</div>
        </div>
        <div class="item fl">
          <div class="number">多渠道多类型多模式的联通能力</div>
          <div class="text">提供ERP、电商、物流、电子发票等多渠道，打通企业内外部业务及数据</div>
        </div>
        <div class="item fl">
          <div class="number">多云适配能力</div>
          <div class="text">支持阿里云、华为云、微软云等技术生态，让企业拥有更多选择</div>
        </div>
      </div>

      <div class="chart-title">行业解决方案</div>
      <div class="data-box clearfix">
        <div class="wrap">
          <!-- Nav tabs -->
          <el-tabs type="border-card">
            <el-tab-pane>
              <span slot="label"> 日化行业</span>
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="home">
                  <div class="target" id="target-1">
                    <div>
                      <div class="block">
                        <el-carousel trigger="click" height="308px" :autoplay="false">
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t1.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G日化行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t2.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G日化行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t3.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G日化行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t4.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G日化行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"> 应急行业</span>
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane" id="profile">
                  <div class="target" id="target-2">
                    <div>
                      <div class="block">
                        <el-carousel trigger="click" height="308px" :autoplay="false">
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t1.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G应急行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t2.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G应急行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t3.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G应急行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t4.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G应急行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"> 酒水行业</span>
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane" id="messages">
                  <div class="target" id="target-3">
                    <div>
                      <div class="block">
                        <el-carousel trigger="click" height="308px" :autoplay="false">
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t1.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G酒水行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t2.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G酒水行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t3.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G酒水行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t4.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G酒水行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"> 房地产行业</span>
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane" id="settings">
                  <div class="target" id="target-4">
                    <div>
                      <div class="block">
                        <el-carousel trigger="click" height="308px" :autoplay="false">
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t1.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G房地产行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t2.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G房地产行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t3.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G房地产行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t4.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G房地产行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane>
              <span slot="label"> 汽车行业</span>
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane" id="car">
                  <div class="target" id="target-5">
                    <div>
                      <div class="block">
                        <el-carousel trigger="click" height="308px" :autoplay="false">
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t1.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t2.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G汽车行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t3.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G汽车行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                          <el-carousel-item>
                            <div style="display: flex">
                              <div class="w50">
                                <img src="../../assets/img/digitalTransformation/t4.png" />
                              </div>
                              <div class="w50">
                                <div style="color: #000; font-weight: bold">P&G汽车行业</div>

                                <p>
                                  响改满表际处持与离年期装议被思段委整维代完情文对白看政格转她形五料况意制支口类万术按便提按元目八单做两分放积大科作物太清正风比。事白管今办反者又压厂江好具区事低京南际金成北外更红空江至极全细上说老后信选分花带调东大它该此作声风由你列想集内值正组系设为林与断二派深须。时儿体三花厂天证完度义给图飞在验周易证观月及之始效着门种矿却选王车目你不过活因特派热却江业速生军列状革委取路非想把真加八因无基真电之信些新决都广作真收。西使采积级次根于政价热众油果带议学治三中美青技高无接存拉有天展精斯前西管习满半部合省斗和没到到活局问者江委农需五清小直头照。济即知或联非开华小且办习每难才公层资花却业加使且车国素连派速示际酸石已类证才形率状广义整品斯需复实何结毛正很斯原元构拉称存。
                                </p>
                              </div>
                            </div>
                          </el-carousel-item>
                        </el-carousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>

          <!-- Tab panes -->
        </div>
      </div>

      <div class="chart-title">联系方式</div>
      <div class="data-box clearfix" style="float: left">
       <el-button type="buttoln" class="btn btn-primary" style="width: 96px; height: 40px; margin-right: 14px; float: left">立即申请</el-button>
        <div class="tel">
          <p class="hotline">全国服务热线（周一到周日 8：00-22：00）</p>
          <p class="number">4008-588-669</p>
        </div>

        <div class="contact-info">
          <p><span class="name">公司名称:</span> 中国IBM科技有限公司</p>
          <p><span class="name">地址:</span> 抗疫物资</p>
          <p><span class="name">邮编:</span> 大中小</p>
          <p>
            <span class="name">微信客服</span>
            <br />
            <img src="../../assets/img/digitalTransformation/erCode.png" alt="codePic" width="56" height="56" style="margin-top: 4px" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper';
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
html,
body,
div,
span,
iframe {
  margin: 0;
  padding: 0;
}

body {
  overflow-y: auto;
}

/deep/ .el-tabs__item{
    color: #333333 !important;
}
/deep/ .el-tabs__item.is-active{
    color: #0E459C !important;
}
/deep/ .el-carousel__arrow{
    background-color: rgba(0,0,0,0.6);
}
.tit {
  /* width: 300px; */
  height: 35px;
  text-align: center;
  background-color: #f3f3f3;
  margin-left: 20px;
}

.tit span {
  float: left;
  width: 100px;
  line-height: 35px;
  cursor: hand;
  cursor: pointer;
}

.con {
  width: 100%;
}

.eClassTab-examSel {
  background: #2d8cee;
  color: #fff;
  border-color: #2d8cee !important;
}

.itemBankDoor {
  color: #2d8cee;
  font-size: 14px;
  text-decoration: underline;
  margin-right: 30px;
}

/* 作业测验列表右侧我的题库入口 */
.itemBankDoor:hover {
  color: #2d8cee;
  font-size: 14px;
  text-decoration: underline;
  margin-right: 30px;
}

/* 作业测验列表右侧我的题库入口 */
.content {
  transition: all 0.3s;
  position: relative;
  height: 100%;
}

.dataOverview,
.chartView,
.postView {
  margin: 16px;
}

.dataOverview {
  padding: 16px;
  // box-sizing: border-box;
  background-color: #fff;
}

.dataOverview .top {
  padding-bottom: 15px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  .line {
    width: 4px;
    height: 20px;
    background: #4393f8;
    border-radius: 3px;
    margin-right: 10px;
  }
}

.dataOverview .title {
  font-family: MicrosoftYaHei-Bold;
  font-size: 26px;
  color: #333333;
  letter-spacing: 0;
  line-height: 36px;
}

.dataOverview .infotext {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
  line-height: 22px;
}

.server {
  display: flex;
}

.data-box {
  // display: flex;
}

.data-box .item {
  height: 118px;
  margin-right: 16px;
  width: calc((100% - 48px) / 4);
  border-radius: 4px;
  padding: 16px;
}

.data-box .item:nth-of-type(1) {
  background: url(../../assets/img/digitalTransformation/szhgz_img_fwys1.png) 8px 8px no-repeat, linear-gradient(269deg, #f7fbff 0%, #eff7ff 100%);
}

.data-box .item:nth-of-type(2) {
  background: url(../../assets/img/digitalTransformation/szhgz_img_fwys2.png) 8px 8px no-repeat, linear-gradient(269deg, #f7fbff 0%, #eff7ff 100%);
}

.data-box .item:nth-of-type(3) {
  background: url(../../assets/img/digitalTransformation/szhgz_img_fwys3.png) 8px 8px no-repeat, linear-gradient(269deg, #f7fbff 0%, #eff7ff 100%);
}

.data-box .item:nth-of-type(4) {
  margin-right: 0;
  background: url(../../assets/img/digitalTransformation/szhgz_img_fwys4.png) 8px 8px no-repeat, linear-gradient(269deg, #f7fbff 0%, #eff7ff 100%);
}

.data-box .item .number {
  font-size: 14px;
  color: #000000;
  margin-bottom: 10px;
}

.data-box .item .text {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #666666;
  letter-spacing: 0;
  line-height: 22px;
}

.data-box .tel {
  float: left;
}

.data-box .tel .hotline {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #666666;
  line-height: 20px;
}

.data-box .tel .number {
  font-family: MicrosoftYaHei-Bold;
  font-size: 18px;
  color: #333333;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 600;
}

.dataOverview .chart-title {
  font-family: MicrosoftYaHei-Bold;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  letter-spacing: 0;
  line-height: 20px;
  padding: 15px 0;
}

.contact-info {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #333333;
  line-height: 20px;
  margin-top: 60px;
}

.contact-info p {
  margin: 15px 0;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  line-height: 20px;
}

.contact-info p .name {
  color: #666;
}

/* tab */
.nav-tab {
  background: #f5f5f5;
  border: none;
  /* border-bottom: 1px solid #ddd; */
}

.nav-tab > li {
  float: left;
  margin-bottom: -1px;
}

.nav-tab > li > a {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  border: 1px solid transparent;
  padding: 12px 15px;
}

/* .nav-tab>li>a:hover{
            color: #555;
        } */
/* .nav-tab>li>a,
        .nav-tab>li>a:hover,
        .nav-tab>li>a:focus {
            color: #555;
            background-color: #fff;
            border: 1px solid #ddd;
            border-bottom-color: transparent;
        } */
.nav-tab > li.active > a,
.nav-tab > li.active > a:hover,
.nav-tab > li.active > a:focus {
  color: #0e459c;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.target {
  width: 100%;
  height: 308px;
  overflow: hidden;
  position: relative;
  /* margin:20px auto; */
}

.w50 {
  height: 308px;
  width: 50%;
}

.target img {
  width: 100%;
  height: 100%;
}

.tab-content {
  padding: 0;
  padding-top: 16px;
  margin-bottom: 0;
  background-image: linear-gradient(269deg, #f7fbff 0%, #eff7ff 100%);
  p {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #666666;
    letter-spacing: 0;
    line-height: 22px;
  }
}

.tab-content .target-text {
  background-image: linear-gradient(269deg, #f7fbff 0%, #eff7ff 100%);
  padding: 16px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #666666;
  line-height: 22px;
}

.tabs-row {
  display: flex;
}

.btn-primary {
  background: #024a8f;
  border-radius: 4px;
  border-radius: 4px;
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
}
</style>